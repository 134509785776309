import edu from'../../assets/img/icons/icon-edu.svg?react';
import salud from'../../assets/img/icons/icon-salud.svg?react';
import inclusion from'../../assets/img/icons/icon-inclusion.svg?react';
import ecologia from'../../assets/img/icons/icon-ecologia.svg?react';
import emergencias from'../../assets/img/icons/icon-emergencias.svg?react';
import { motion } from "framer-motion";
import { fadeIn, fadeInContainerItemsAll, fadeInItem } from '../../variants';
const Pilares = () => {
    return (
        <>
        <motion.article
        variants={fadeIn("down", 0.4)}
        initial="hidden"
        whileInView={"show"}
        viewport={{ once: false, amount: 0.3 }}
        className="overflow-hidden flex flex-col justify-center items-center text-[#093067] max-md:text-center mt-10">
            <h2 className="text-title_lanza">EL DEPORTE ES INSPIRACIÓN PARA AYUDAR</h2>
            <h3 className="text-title-nuestrosp">Nuestros Pilares:</h3>
        </motion.article>
        <motion.div 
        variants={fadeInContainerItemsAll}
        initial="hidden"
        whileInView="visible"
        viewport={{ once: false, amount: 0.3 }}
        className="overflow-hidden flex flex-wrap justify-between gap-y-3 align-content-center p-10 text-[#093067]">
            <motion.div 
            variants={fadeInItem}
            className="w-1/5 text-center max-md:w-1/3 text-title-pilares"><img src={edu} alt="edu" className='mx-auto sm:my-6 my-5 sm:p-0 px-5' />Educación
            </motion.div>
            <motion.div 
            variants={fadeInItem}
            className="w-1/5  text-center max-md:w-1/3 text-title-pilares"><img src={salud} alt="salud" className='mx-auto sm:my-6 my-5 sm:p-0 px-5' />Salud
            </motion.div>
            <motion.div 
            variants={fadeInItem}
            className="w-1/5  text-center max-md:w-1/3 text-title-pilares"><img src={inclusion} alt="inclusion" className='mx-auto sm:my-6 my-5 sm:p-0 px-5' />Inclusión
            </motion.div>
            <motion.div 
            variants={fadeInItem}
            className="w-1/5  text-center max-md:w-1/2 text-title-pilares"><img src={ecologia} alt="ecologia" className='mx-auto sm:my-6 my-5 sm:p-0 px-12' />Ecología
            </motion.div>
            <motion.div 
            variants={fadeInItem}
            className="w-1/5  text-center max-md:w-1/2 text-title-pilares"><img src={emergencias} alt="emergencias" className='mx-auto sm:my-6 my-5 sm:p-0 px-12' />Emergencia
            </motion.div>
        </motion.div>
        </>
    );
}

export default Pilares;