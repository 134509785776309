import alas from'../../assets/img/Lanzamiento/lanzamientoalas.png?react';
import { Link } from 'react-router-dom';
import { motion } from "framer-motion";
import { fadeIn } from '../../variants';
import CountUp from 'react-countup';

const Lanzamiento = () => {
    return (  
        <>
         <div className="overflow-hidden bg-gradient-to-b from-[#fff] to-[#fdf6e3] sm:p-10 p-5">
          <div className="flex flex-wrap justify-center items-center text-[#093067]">
            <motion.article 
            variants={fadeIn("left", 0.4)}
            initial="hidden"
            whileInView={"show"}
            viewport={{ once: false, amount: 0.3 }}
            className="max-md:p-0 md:w-1/2 max-md:w-full max-md:text-center pr-10 max-md:mb-10">
              <h2 className="text-titles-segundo">LANZAMIENTO NUESTRAS ALAS.</h2>
              <p className="textsSegundo">
                Club América lanza Nuestras Alas, su proyecto de responsabilidad social.
                Todos los esfuerzos de este programa son operados por Club América y Fundación Televisa.
              </p>
              <p className="textsSegundo">
                Nuestras Alas busca lograr un impacto permanente en la sociedad y en las
                próximas generaciones a través de acciones de responsabilidad social.
              </p>
              <Link to="https://www.youtube.com/watch?v=ng3udWOLv00" target="_blank" className="max-md:hidden mt-10 boton-css" rel="noopener noreferrer">
                VER VIDEO
              </Link>
            </motion.article>
            <motion.figure 
            variants={fadeIn("right", 0.4)}
            initial="hidden"
            whileInView={"show"}
            viewport={{ once: false, amount: 0.3 }}
            className="md:w-1/2 max-md:w-full">
                <img src={alas} alt="alas" className="mx-auto w-full image1" />
            </motion.figure>
            <Link to="https://www.youtube.com/watch?v=ng3udWOLv00" target="_blank" className="text-blue-600 font-bold md:hidden mt-10 boton-css" rel="noopener noreferrer">
                VER VIDEO 
            </Link>
          </div>

          <div className="flex flex-col justify-center items-center py-10 mt-10">
            <p className="texts-pil">
              Gracias al trabajo en equipo el programa logró:
            </p>
            
            <CountUp className='texts-contador leading-none'
              enableScrollSpy={true}
              start={0}
              end={45165}
              duration={3}
            />
            
            <h2 className="texts-bene">
              BENEFICIADOS.
            </h2>
          </div>
        </div>
        </>  

    );
}
export default Lanzamiento;