import JornadaCA from'../../assets/img/Jornada Rosa/img-jornadacancerdemama.png';
import Visita from'../../assets/img/Jornada Rosa/img-visitahospitalesycrits.png';
import Ballon from '../../atoms/Ballon/Ballon';
import tacos from '../../assets/img/tacos.png';
import { motion } from "framer-motion";
import { fadeIn } from '../../variants';
import './JornadaR.css';

const JornadaR = () => {

    return (  
        <>
            <div className='flex flex-wrap mt-10 max-md:gap-10 overflow-hidden'>
                <motion.article 
                variants={fadeIn("left", 0.4)}
                initial="hidden"
                whileInView={"show"}
                viewport={{ once: false, amount: 0.3 }}
                className="px-10 w-1/2 max-md:w-full max-md:text-center">
                    <h2 className="text-titles-jornada">JORNADA ROSA CONTRA EL CÁNCER DE MAMA.</h2>
                    <p className="textsJornada">
                        En el marco de Mes Rosa, se realizó un evento de tiro de penales entre mujeres
                        que practican el futbol y jugadores de Club América Femenil en Tepoztlán, Morelos, 
                        para apoyar con mastografías y ultrasonidos a la comunidad, a través de TepozRosa. Los tiros 
                        se transformaron en 100 mujeres beneficiadas con estudios para prevenir el cáncer de mama. 
                    </p>
                </motion.article>
                <motion.figure  
                variants={fadeIn("right", 0.4)}
                initial="hidden"
                whileInView={"show"}
                viewport={{ once: false, amount: 0.3 }}
                className='w-1/2 max-md:w-full'>
                    <img src={JornadaCA} alt="JornadaCA" className='w-full'/>
                </motion.figure>
                <motion.figure  
                variants={fadeIn("left", 0.4)}
                initial="hidden"
                whileInView={"show"}
                viewport={{ once: false, amount: 0.3 }}
                className='w-1/2 max-md:order-2 max-md:w-full relative'>
                    <img src={Visita} alt="Visita" className='w-full mt-10 -ml-10 max-md:m-0' />
                    <Ballon nameOfClass='text-ballon absolute right-0 -top-6' src={tacos}></Ballon>
                </motion.figure>
                <motion.article 
                variants={fadeIn("right", 0.4)}
                initial="hidden"
                whileInView={"show"}
                viewport={{ once: false, amount: 0.3 }}
                className="w-1/2 max-md:order-1 max-md:w-full sm:pr-10 sm:pl-5 px-6 mt-10 max-md:m-0 max-md:text-center">
                    <h2 className="text-titles-jornada">VISITAS A HOSPITALES Y CRITS.</h2>
                    <p className="textsJornada">
                        En conjunto con Fundación Dr. Sonrisas se han realizado visitas a los hospitales
                        Centro Médico Instituto Nacional de Pediatría, donde se contó con la presencia de 
                        Henry Martin, Karel Campos, Leonardo Palestina, Igor Lichnovsky y Miguel Layún, 
                        quienes regalaron a juguetes a 82 niñas y niños con problemas de salud.  
                    </p>
                    <h1 className="text-title-crit sm:p-5">
                        CRIT Ciudad de México
                    </h1>
                    <div className='text-title-crit-parrafo'>
                       <ul>
                            <li className="list-item-orange">Entrega de juguetes y convivencia del primer equipo varonil con <br className='sm:block hidden'/>los niños.</li>
                            <li className="list-item-orange pt-4">Convivencia con los niños y partido en silla de ruedas del primer<br className='sm:block hidden'/> equipo femenil. </li>
                        </ul>
                    </div>
                    
                   
                </motion.article>
            </div>
        </>
    );
}
export default JornadaR;