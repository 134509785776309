import FundacionesIn from'../../assets/img/Fundaciones Invitadas/balon-fundacionesinvitadas.svg?react';
import { motion } from 'framer-motion';

const Fundaciones = () => {
    const rotateVariants = {
        hidden: { rotate: 0 },
        visible: { 
          rotate: 360,
          transition: {
            duration: 10, // Duración del giro en segundos
            repeat: Infinity, // Repetir infinitamente
            ease: 'linear', // Efecto de transición lineal
          }
        }
    };

    return (  
        <>
            <div className="relative w-full min-h-96 overflow-hidden">
                {/* Fondo giratorio */}
                <motion.div
                    variants={rotateVariants}
                    initial="hidden"
                    animate="visible"
                    className="absolute inset-0 bg-gradient-to-r from-blue-500 max-w-full to-purple-500"
                    style={{ 
                        backgroundImage: `url(${FundacionesIn})`, 
                        zIndex: -1, 
                        backgroundPosition: 'center', 
                        backgroundRepeat: 'no-repeat', 
                        backgroundSize: 'contain' 
                      }}
                      
                />

                {/* Contenido encima del fondo giratorio */}
                <div className="relative z-10 w-full h-full">
                    
                        <h2 className="flex justify-center items-center text-titles text-center">
                            FUNDACIONES INVITADAS <br/>A PARTIDOS:
                        </h2>
                        <div className="flex flex-wrap justify-stretch items-center px-20 max-md:px-5 max-md:gap-0 text-title-fundaciones-parrafo mx-auto max-w-5xl ">
                            <ul className='w-1/3 max-sm:w-full sm:space-y-4 space-y-2'>
                            <li className='list-item-orange'>A Favor del Niño</li>
                            <li className='list-item-orange'>Bécalos</li>
                            <li className='list-item-orange'>CRIT</li>
                            <li className='list-item-orange'>Centro Médico</li>
                            </ul>
                            
                            <ul className='w-1/3 max-sm:w-full sm:space-y-4 space-y-2 sm:my-0 my-2'>
                            <li className='list-item-orange'>Fondo Semillas</li>
                            <li className='list-item-orange'>Instituto Nacional de Pediatría</li>
                            <li className='list-item-orange'>Schoolas</li>
                            <li className='list-item-orange'>Tecnolochicas</li>
                            </ul>

                            <ul className='w-1/3 max-sm:w-full sm:space-y-4 space-y-2 '>
                            <li className='list-item-orange'>COI</li>
                            <li className='list-item-orange'>Comunidad Mazahua</li>
                            <li className='list-item-orange'>Brazos de apoyo</li>
                            <li className='list-item-orange'>Cruz Roja</li>
                            </ul>
                        </div>

                </div>
                </div>
        </>
    );
}
export default Fundaciones;