import uno from'../../assets/img/icons/1.png?react';
import dos from'../../assets/img/icons/2.png?react';
import tres from'../../assets/img/icons/3.png?react';
import cuatro from'../../assets/img/icons/4.png?react';
import cinco from'../../assets/img/icons/5.png?react';
import seis from'../../assets/img/icons/6.png?react';
import siete from'../../assets/img/icons/7.png?react';
import ocho from'../../assets/img/icons/8.png?react';
import nueve from'../../assets/img/icons/9.png?react';
import { motion } from "framer-motion";
import { fadeIn, fadeInContainerItemsAll } from '../../variants';

const Onu = () => {
    return (  
        <>
            <motion.div 
            variants={fadeIn("down", 0.4)}
            initial="hidden"
            whileInView={"show"}
            viewport={{ once: false, amount: 0.1 }}
            className="flex flex-col justify-center items-center text-center p-10 overflow-hidden">
                <h1 className="text-titles">
                    OBJETIVOS DE DESARROLLO <br />SOSTENIBLE DE LA ONU.
                </h1>
                <p className="texts mx-20 max-md:mx-0">Buscamos que nuestros pilares se alineen a los Objetivos de Desarrollo Sostenible (ODS) de la UNU.
                Con nuestras acciones creamos un impacto positivo en nuestras comunidades y avanzamos hacia el futuro 
                más sotenible. </p>
            </motion.div>
            <motion.div 
            variants={fadeInContainerItemsAll}
            initial="hidden"
            whileInView="visible"
            viewport={{ once: false, amount: 0.1 }}
            className='sm:flex-row flex justify-between flex-wrap align-content-center sm:gap-6 gap-2 sm:pb-20 pb-10 bg-gradient-to-b from-[#fff] to-[#eee99c] overflow-hidden xsm:hidden xl:flex'>
                <img src={uno} alt="uno" className='mx-auto sm:my-6 my-1' />
                <img src={dos} alt="dos" className='mx-auto sm:my-6 my-1' />
                <img src={tres} alt="tres" className='mx-auto sm:my-6 my-1' />
                <img src={cuatro} alt="cuatro" className='mx-auto sm:my-6 my-1' />
                <img src={cinco} alt="cinco" className='mx-auto sm:my-6 my-1' />
                <img src={seis} alt="seis" className='mx-auto sm:my-6 my-1' />
                <img src={siete} alt="siete" className='mx-auto sm:my-6 my-1' />
                <img src={ocho} alt="ocho" className='mx-auto sm:my-6 my-1' />
                <img src={nueve} alt="nueve" className='mx-auto sm:my-6 my-1' />
            </motion.div>

            <motion.div 
            variants={fadeInContainerItemsAll}
            initial="hidden"
            whileInView="visible"
            viewport={{ once: false, amount: 0.1 }}
            className='flex justify-center align-content-center pb-20  overflow-hidden xl:hidden xsm:flex max-xsm:hidden'>
                <img src={uno} alt="uno" className='mx-auto my-6 lg:my-7' />
                <img src={dos} alt="dos" className='mx-auto my-6 lg:my-7' />
                <img src={tres} alt="tres" className='mx-auto my-6 lg:my-7' />
                <img src={cuatro} alt="cuatro" className='mx-auto my-6 lg:my-7' />
                <img src={cinco} alt="cinco" className='mx-auto my-6 lg:my-7' />
                </motion.div>
            <motion.div 
            variants={fadeInContainerItemsAll}
            initial="hidden"
            whileInView="visible"
            viewport={{ once: false, amount: 0.1 }}
            className='flex justify-center align-content-center pb-20 bg-gradient-to-b from-[#fff] to-[#eee99c] overflow-hidden xl:hidden xsm:flex max-xsm:hidden'>
                <img src={seis} alt="seis" className='mx-auto my-6 lg:my-7' />
                <img src={siete} alt="siete" className='mx-auto my-6 lg:my-7' />
                <img src={ocho} alt="ocho" className='mx-auto my-6 lg:my-7' />
                <img src={nueve} alt="nueve" className='mx-auto my-6 lg:my-7' />
                </motion.div>
        </>
    );
}
export default Onu;