import { useState } from 'react';
import { Link } from 'react-router-dom';
import { FaBars } from 'react-icons/fa'; 

const Menu = () => {
    const [isOpen, setIsOpen] = useState(false);

    const scrollToElement = (id) => {
        const element = document.getElementById(id);
        if (element) {
          element.scrollIntoView({ behavior: 'smooth' });
        }
    };

    const toggleMenu = () => {
        setIsOpen(!isOpen);
    };

    return (
        <div>
            {/* Botón de hamburguesa visible solo en pantallas menores a 786px */}
            <div className=" fixed top-5 right-5 z-50">
                <button onClick={toggleMenu} className="text-gray">
                    <FaBars size={30} />
                </button>
            </div>

            {/* Menú visible solo en pantallas mayores a 786px 
            <ul className="fixed top-14 right-10 text-title-menu 3xl:w-[40rem] xl:w-[20rem] lg:w-[17rem] md:w-[10rem] mdf:w-[16rem] z-50 max-mdf:hidden">
                <li style={{ textShadow: '2px 2px 4px rgba(0, 0, 0, 0.8)' }} className='py-2'>
                    <Link to="#" onClick={() => scrollToElement('nuestras_alas')}>NUESTRAS ALAS</Link>
                </li>
                <li style={{ textShadow: '2px 2px 4px rgba(0, 0, 0, 0.8)' }} className='py-2'>
                    <Link to="#" onClick={() => scrollToElement('aguilas_tecnologicas')}>ÁGUILAS TECNOLÓGICAS</Link>
                </li>
                <li style={{ textShadow: '2px 2px 4px rgba(0, 0, 0, 0.8)' }} className='py-2'>
                    <Link to="#" onClick={() => scrollToElement('equipos_extraordinarios')}>EQUIPOS EXTRAORDINARIOS</Link>
                </li>
                <li style={{ textShadow: '2px 2px 4px rgba(0, 0, 0, 0.8)' }} className='py-2'>
                    <Link to="#" onClick={() => scrollToElement('juega_con_nike')}>JUEGA CON NIKE</Link>
                </li>
                <li style={{ textShadow: '2px 2px 4px rgba(0, 0, 0, 0.8)' }} className='py-2'>
                    <Link to="#" onClick={() => scrollToElement('eventos')}>EVENTOS</Link>
                </li>
                <li style={{ textShadow: '2px 2px 4px rgba(0, 0, 0, 0.8)' }} className='py-2 hidden'>
                    <Link to="/en" >EN</Link> | <Link to="/">ES</Link>
                </li>
            </ul>*/}

            {/* Menú desplegable a pantalla completa visible solo en pantallas menores a 786px */}
            <div className={`fixed top-0 right-0 w-full h-full bg-[#104896]  bg-opacity-75 text-title-menu z-40 ${isOpen ? 'block' : 'hidden'}`} onClick={toggleMenu}>
                <ul className="flex flex-col items-center sm:items-start sm:ps-3 ps-0 justify-center h-full space-y-6">
                    <li>
                        <Link to="#" onClick={() => scrollToElement('nuestras_alas')}>NUESTRAS ALAS</Link>
                    </li>
                    <li>
                        <Link to="#" onClick={() => scrollToElement('aguilas_tecnologicas')}>ÁGUILAS TECNOLÓGICAS</Link>
                    </li>
                    <li>
                        <Link to="#" onClick={() => scrollToElement('equipos_extraordinarios')}>EQUIPOS EXTRAORDINARIOS</Link>
                    </li>
                    <li>
                        <Link to="#" onClick={() => scrollToElement('juega_con_nike')}>JUEGA CON NIKE</Link>
                    </li>
                    <li>
                        <Link to="#" onClick={() => scrollToElement('eventos')}>EVENTOS</Link>
                    </li>
                </ul>
            </div>
        </div>
    );
};

export default Menu;
