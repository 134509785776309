import { motion } from "framer-motion";
import { fadeIn } from '../../variants';

const MotionFigure = ({ image, nameOfClass, direction, delay }) => {

    return (
        <>
            <motion.figure 
            variants={fadeIn(direction, delay)}
            initial="hidden"
            whileInView={"show"}
            viewport={{ once: false, amount: 0.3 }}
            className={nameOfClass + ' w-full'}>
                <img src={image} alt="Becas" className='w-full' />
            </motion.figure>
        </>
    )
}

export default MotionFigure;