
const Semestre = ({children}) => {

    return (  
        <>
        <div className="flex flex-col text-center items-center my-10 overflow-hidden sm:mx-0 mx-5">
            <p className="text-parallax-parrafo">{children}</p>
        </div>
        </>
    );
}
export default Semestre;