import toneladas from '../../assets/img/Tenis/15-toneladas.png?react';
import becas from '../../assets/img/Tenis/becas-aguilas.png?react';
import { motion } from "framer-motion";
import { fadeIn } from '../../variants';
import Ballon from '../../atoms/Ballon/Ballon';
import playera from '../../assets/img/playera.png';
import MotionFigure from '../../atoms/Motion/MotionFigure';
import './Tenis.css';

const Tenis = () => {
    return (
        <>
            <div className="overflow-hidden boxTenis mt-5">
                <motion.article 
                variants={fadeIn("left", 0.4)}
                initial="hidden"
                whileInView={"show"}
                viewport={{ once: false, amount: 0.3 }}
                className='boxTenis1 px-10 max-md:px-2 max-md:text-center xl:pt-20'>
                    <h2 className='text-titles'>Tenis con alas.</h2>
                    <p className='texts xl:mb-6 lg:mb-6'>
                        Iniciativa enfocada en recolectar y reciclar textiles, para combatir la gran cantidad de desperdicios que la industria textil genera.
                    </p>
                    <br />
                    <p className='texts'>
                        Con 3 contenedores inteligentes, el programa hace una campaña de concientización para que aficionados y visitantes al estadio donen textiles y cuiden el planeta.
                    </p>
                </motion.article>
                <motion.article 
                variants={fadeIn("left", 0.4)}
                initial="hidden"
                whileInView={"show"}
                viewport={{ once: false, amount: 0.3 }}
                className='boxTenis3 bg-[#F0EB9A] p-5 sm:pl-10'>
                    <span className='title-cuadro-tenis-con-alas'>Con esta iniciativa contribuimos a disminuir la huella de carbono y ahorramos un aproximado de 165 mil litros de agua. </span><br />
                    <span className='title-cuadro-tenis-con-alas-sub'>Cada tonelada de ropa producida gasta 11 millones de litros de agua, UNAM.</span>
                </motion.article>
                <figure className='boxTenis2'>
                    <motion.img 
                    variants={fadeIn("right", 0.4)}
                    initial="hidden"
                    whileInView={"show"}
                    viewport={{ once: false, amount: 0.3 }}
                    src={toneladas} 
                    alt="15 toneladas de tenis recolectados" 
                    className='w-full tenis1' />
                </figure>
                <article className='boxTenis4 boxWithBallon'>
                    <MotionFigure image={becas} nameOfClass='becasImg' direction="left" delay={0.4} />
                    <Ballon nameOfClass='ballonTenis text-ballon' src={playera}></Ballon>
                </article>
                <motion.article 
                variants={fadeIn("right", 0.4)}
                initial="hidden"
                whileInView={"show"}
                viewport={{ once: false, amount: 0.3 }}
                className='boxTenis5 p-5 max-md:px-2 max-md:text-center '>
                    <h2 className='text-titles'>becas águilas.</h2>
                    <p className='texts'>
                    Se entregaron becas de excelencia académica a jóvenes que cursan media superior y superior en escuelas públicas del país, para el ciclo escolar 2023-2024, con el objetivo de minimizar la deserción escolar y brindarles mejores oportunidades
                    </p>
                    <br />
                    <p className='title-sub-becas-aguilas'>
                        Entregamos una beca por cada jugador que entrena en Club América.
                    </p>
                </motion.article>
                <motion.article 
                variants={fadeIn("right", 0.4)}
                initial="hidden"
                whileInView={"show"}
                viewport={{ once: false, amount: 0.3 }}
                className='boxTenis6 bg-[#F0EB9A] p-5 sm:pl-10'>
                    <span className='title-cuadro-becas-aguilas-alas'>“Gracias a Nuestras Alas y su beca podré ser el primero de <br className='sm:block hidden'/>mi familia en terminar una carrera universitaria.”</span><br />
                    <span className='title-cuadro-becas-aguilas-sub'>-Aselmo Téllez</span>
                </motion.article>
            </div>
        </>
    );
}

export default Tenis;