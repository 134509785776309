export const fadeIn = (direction, delay) => {
    return {
        hidden: {
            opacity: 0, 
            y: direction === 'up' ? 20 : direction === 'down' ? -50 : 0,
            x: direction === 'left' ? -100 : direction === 'right' ? 100 : 0,
        },
        exit: { 
            opacity: 0, 
            y: direction === 'up' ? 20 : direction === 'down' ? -50 : 0,
            x: direction === 'left' ? -100 : direction === 'right' ? 100 : 0,
            transition: { duration: 0.5 } 
        },
        show: {
            y:0,
            x:0,
            opacity: 1,
            transition: {
                type: 'tween',
                duration: 1.2,
                delay: delay,
                ease: [0.25, 0.25, 0.25, 0.75],
            }
        },
    };
};

export const fadeInContainerItemsAll = {
    hidden: { 
        opacity: 0, 
        y: -50 
    },
    visible: {
        opacity: 1,
        y: 0,
    },
};

export const fadeInContainerItemToItem = {
    hidden: { 
        opacity: 0, 
        y: 20 
    },
    visible: {
        opacity: 1,
        y: 0,
        transition: {
            staggerChildren: 0.3,
        },
    },
};

export const fadeInItem = {
    hidden: { opacity: 0, scale: 0.3 },
    visible: { opacity: 1, scale: 1, transition: { duration: 0.5 } },
};