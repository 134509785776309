import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, useParams } from 'react-router-dom';
import './i18n.js'; 
import Home from './pages/Home';
import i18n from 'i18next';

const LanguageWrapper = ({ element: Component }) => {
  const { lng } = useParams(); 

  useEffect(() => {
    const language = lng || 'es'; 
    if (language !== undefined) {
      i18n.changeLanguage(language); 
    }
  }, [lng]);

  return <Component />; 
};

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/:lng" element={<LanguageWrapper element={Home} />} />
        <Route path="/" element={<LanguageWrapper element={Home} />} />
      </Routes>
    </Router>
  );
}

export default App;
