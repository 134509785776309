
const Futbol = () => {
    return (  
        <>
           <div className="overflow-hidden flex flex-col justify-center items-center bg-[#093067] text-white py-20 px-4">
                <p className="text-center text-title-txt md:px-28 ">
                    “El futbol es un instrumento de unión. Sabemos quiénes somos, conocemos la importancia y la responsabilidad que tiene el club, no sólo en la parte deportiva, sino también en el ámbito social.”
                </p>
                <p className="text-title-sub text-sm  mt-4 ">
                     EMILIO AZCÁRRAGA JEAN
                </p>
            </div>
        </>  
    
        );
}
export default Futbol;
   
