import equiposextraodinarios from '../../assets/img/Equipos Extraodinarios/img-equiposextraodinarios.png';
import equiposamputados from '../../assets/img/Equipos Extraodinarios/img-equiposamputados.png';
import { Link } from 'react-router-dom';
import { motion } from "framer-motion";
import { fadeIn } from '../../variants';
import './EquiposEstraordinarios.css';

const EquiposEstraordinarios = () => {
    return (  
        <>
            <div className='sm:pt-10 pt-10 boxEquiposExtraordinarios bg-[#093067] text-white overflow-hidden' id="equipos_extraordinarios">
                <motion.article  
                variants={fadeIn("left", 0.4)}
                initial="hidden"
                whileInView={"show"}
                viewport={{ once: false, amount: 0.3 }}
                className="boxEquiposExtraordinarios1 pl-10 max-md:pl-5">
                    <h2 className="text-extraodinario-title md:mt-[3.25rem]">
                        Equipos extraodinarios.
                    </h2>
                    <p className="text-extraodinario-parrafo">
                        Impulsamos el desarrollo de niños, niñas y jóvenes al <br /> 
                        brindarles un espacio seguro e incluyente para practicar su <br /> 
                        deporte favorito.
                    </p>
                    <p className="text-extraodinario-beneficios md:mt-[3.25rem]">
                        119 BENEFICIADOS <br /> EN 9 CATEGORÍAS
                    </p>
                </motion.article >
                <motion.figure 
                variants={fadeIn("right", 0.4)}
                initial="hidden"
                whileInView={"show"}
                viewport={{ once: false, amount: 0.3 }}
                className="boxEquiposExtraordinarios2">
                    <img src={equiposextraodinarios} alt="equiposextraodinarios" className='w-full' />
                </motion.figure>
                <motion.blockquote 
                variants={fadeIn("right", 0.4)}
                initial="hidden"
                whileInView={"show"}
                viewport={{ once: false, amount: 0.3 }}
                className="boxEquiposExtraordinarios3">
                    <div className='bg-white text-black p-3'>
                        <p className="text-extraodinario-bloque">"En el Club América, además de marcar goles, también marcamos la diferencia".</p>
                        <footer className="text-extraodinario-bloque-emilio">- EMILIO AZCÁRRAGA JEAN</footer>
                    </div>
                </motion.blockquote>
                <motion.figure 
                variants={fadeIn("left", 0.4)}
                initial="hidden"
                whileInView={"show"}
                viewport={{ once: false, amount: 0.3 }}
                className="boxEquiposExtraordinarios4">
                    <img src={equiposamputados} alt="equiposamputados" className='w-full' />
                </motion.figure>
                <motion.article 
                variants={fadeIn("right", 0.4)}
                initial="hidden"
                whileInView={"show"}
                viewport={{ once: false, amount: 0.3 }}
                className="boxEquiposExtraordinarios5 max-md:text-right max-md:pr-5 p-3">
                    <h2 className="text-extraodinario-title-sub">EQUIPOS DE AMPUTADOS.</h2>
                        <p className="text-extraodinario-parrafo-sub">
                        Se realizó un evento especial para presentar oficialmente al equipo. En la ceremonia inaugural 
                        se llevaron a cabo dos partidos mixtos. Los grupos contendientes estuvieron integrados por jugadores
                        del equipo de amputados con jugadoras y jugadores de los dos primeros equipos del Club América.
                        </p>
                        <Link to="https://www.youtube.com/watch?v=cD95Wpj0qKI&t=210s" target="_blank" className="boton-css mt-4 text-video-white" rel="noopener noreferrer">
                            VER VIDEO
                        </Link>
                </motion.article>
            </div>
        </>
    );
}
export default EquiposEstraordinarios;