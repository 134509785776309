import Slider from "react-slick";
import uno from '../../assets/img/Eventos/1.png';
import dos from '../../assets/img/Eventos/2.png';
import tres from '../../assets/img/Eventos/3.png';
import cuatro from '../../assets/img/Eventos/4.png';
import cinco from '../../assets/img/Eventos/5.png';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './Eventos.css';

const Eventos = () => {
    const settings = {
        dots: true,
        lazyLoad: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 0,
        adaptiveHeight: true,
        autoplay: true,
      };
    return (  
        <>
    <div className="slider-container sm:p-14 px-6 py-12 overflow-hidden " id="eventos">
      <Slider {...settings}>
        <div style={{ width: 100 }}>
          <img src={uno} alt="uno" className="w-full"/>
          <h3 className="title-carousel text-center" >Mes de la mujer:</h3>
          <p className="title-carousel-text text-center">
          Se realizaron acciones de comunicación, digitales y de marketing ; como cambiar el color de los números de camisetas y las redes al color morado en el Estadio Azteca y las instalaciones de Coapa. Se invitó a instituciones  apoyar a la mujer a los partidos América Femenil vs. Puebla y América Varonil vs. Tigres.
          </p>
        </div>
        <div style={{ width: 100 }}>
          <img src={dos} alt="dos" className="w-full"/>
          <h3 className="title-carousel text-center">Mes del autismo:</h3>
          <p className="title-carousel-text text- sm-p-0 p-1">
        En este mes el color de los números de la camiseta se cambió a azul, en los que se incluyeron dibujos realizados por niños con autismo; asimismo el color azul se apreció en las redes del Estadio Azteca y las instalaciones de Coapa.          </p>
        </div>
        <div  style={{ width: 100 }}>
          <img src={tres} alt="tres" className="w-full"/>
          <h3 className="title-carousel text-center " >mes lgbtq+:</h3>
          <p className="title-carousel-text text-center sm-p-0 p-1">
          Se realizaron actividades como poner los números de las camisetas, las redes de portería y los banderines de tiro de esquina con los colores del arcoíris. </p>
        </div>
        <div style={{ width: 100 }}>
          <img src={cuatro} alt="cuatro" className="w-full"/>
          <h3 className="title-carousel text-center ">día del águila real:</h3>
          <p className="title-carousel-text text-center sm-p-0 p-1">
          En redes sociales y medios de comunicación se promueve la alianza entre Nuestras Alas y Reino Animal con una campaña para la preservación del águila real. </p>
        </div>
        <div style={{ width: 100 }}>
          <img src={cinco} alt="cinco" className="w-full"/>
          <h3 className="title-carousel text-center">día del perro:</h3>
          <p className="title-carousel-text text-center sm-p-0 p-1 s">
          Se realizó una campaña de adopción en la explanada del Estadio Azteca. Se invitó a los abonados a ver un partido del primer equipo femenil en las suites 13 y 14 con sus perros. Las jugadoras del primer equipo femenil entraron al protocolo del partido con perros para fomentar la adopción.  </p>
        </div>
      </Slider>
    </div>
        </>
    );
}
export default Eventos;