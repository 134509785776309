import './Juega.css';
const Juega = ({img, texto, id}) => {

    return (  
        <>
            <div className='relative block w-full overflow-hidden min-h-[400px]' id={id}>
                <div className="absolute z-10 parallax-effect-image" style={{ backgroundImage: `url(${img})` }}>
                </div>
                <h1 className="absolute z-20 bottom-0 sm:left-1/2 transform sm:-translate-x-1/2 sm:px-0 px-2 mb-4 text-parallax -translate-y-6 text-center w-full">
                    {texto}
                </h1>
            </div>
        </>
    );
}
export default Juega;