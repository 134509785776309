import copa from'../../assets/img/icons/copa.svg?react';
import './RespaldoCA.css';
import { motion } from "framer-motion";
import { fadeInContainerItemToItem, fadeInItem } from '../../variants';

const RespaldoCA = () => {

    return (  
        <>
            <motion.div 
            variants={fadeInContainerItemToItem}
            initial="hidden"
            whileInView="visible"
            viewport={{ once: false, amount: 0.1 }}
            className='mx-20 max-md:mx-5 boxRespaldo overflow-hidden'>
                <motion.div 
                variants={fadeInItem}
                className="boxRespaldo1 bg-gradient-to-b from-[#fff] to-[#eee99c] p-5">
                    <img src={copa} alt="copa" className='mx-auto' />
                    <p className='text-center'>
                        <span class="text-respaldoca-text"> Gracias a la transmisión de los partidos de Club América, menciones en TUDN y programas de revista, Nuestras Alas alcanzó a más de</span>
                        <span class="text-respaldoca-text-rubik"> 12.8 millones </span>
                        <span class="text-respaldoca-text"> de personas.</span>
                    </p>
                </motion.div>
                <motion.div 
                variants={fadeInItem}
                className="boxRespaldo2 bg-gradient-to-b from-[#fff] to-[#eee99c] p-5">
                    <img src={copa} alt="copa" className='mx-auto' />
                    <p className='text-center'>
                        <span class="text-respaldoca-text-rubik"> 6.8 millones </span>
                        <span class="text-respaldoca-text"> de reproducciones del contenido de Nuestras Alas en redes sociales.  </span>
                    </p>
                </motion.div>
                <motion.div 
                variants={fadeInItem}       
                className="boxRespaldo3 bg-gradient-to-b from-[#fff] to-[#eee99c] p-5">
                    <img src={copa} alt="copa" className='mx-auto' />
                    <p className='text-center'>
                        <span class="text-respaldoca-text"> Nuestro alcance en Redes sociales fue de </span>
                        <span class="text-respaldoca-text-rubik"> 8 millones </span>
                        <span class="text-respaldoca-text"> de aficionados; cada publicación tuvo un impacto de</span>
                        <span class="text-respaldoca-text-rubik">  500 MIL </span>
                        <span class="text-respaldoca-text"> aficionados.</span>
                    </p>
                </motion.div>
            </motion.div>
        </>
    );
}
export default RespaldoCA;