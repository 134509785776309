import Facebook from '../../assets/img/icons/Facebook.svg?react';
import Instagram from '../../assets/img/icons/instagram.svg?react';
import X from '../../assets/img/icons/x.svg?react';
import YT from '../../assets/img/icons/youtube.svg?react';
import logo  from '../../assets/img/logo.svg?react';
import { Link } from 'react-router-dom'
const Footer = () => {
    const scrollToElement = (id) => {
        const element = document.getElementById(id);
        if (element) {
          element.scrollIntoView({ behavior: 'smooth' });
        }
    };
    return (
        <footer className='overflow-hidden'>
            <div className="bg-[#093067] text-white w-full">
                <div className="container mx-auto py-6 flex flex-col">
                    <div className="container mx-auto py-6 flex flex-wrap items-center max-md:gap-4">
                        <div className=" justify-center w-full">
                                <h2 className="text-footer mb-4 text-center">NUESTRAS ALAS</h2>
                        </div>
                        <section className='flex max-xsm:flex-col flew-wrap justify-center content-center items-center xl:mx-5 gap-4 max-lg:gap-2 w-full'>
                            <figure className="justify-left basis-2/10">
                                <img src={logo} className="xl:w-36 xsm:w-25 mx-xsm:w-20 lg:w-20 md:w-14 mx-auto pr-2" alt="Logo" />
                            </figure>
                            <div className="basis-8/10 flex max-lg:flex-wrap max-xsm:flex-col max-xsm:text-center xl:gap-custom-gap mdf:gap-7 max-mdf:gap-3 max-md:w-full text-footer-links">
                                <Link to="#" onClick={() => scrollToElement('nuestras_alas')}>NUESTRAS ALAS</Link>
                                <Link to="#" onClick={() => scrollToElement('aguilas_tecnologicas')}>ÁGUILAS TECNOLÓGICAS</Link>
                                <Link to="#" onClick={() => scrollToElement('equipos_extraordinarios')}>EQUIPOS EXTRAORDINARIOS</Link>
                                <Link to="#" onClick={() => scrollToElement('juega_con_nike')}>JUEGA CON NIKE</Link>
                                <Link to="#" onClick={() => scrollToElement('eventos')}>EVENTOS</Link>
                            </div>
                        </section>
                    </div>
                    
                        
                    <span className="text-footer-derechos ">TODOS LOS DERECHOS RESERVADOS © NUESTRAS ALAS 2024.</span>
                        <hr className="border-t-2 border-white opacity-50 my-4 w-full" />
                    <div className="w-full flex flex-col justify-center items-center gap-4 text-center">
                        
                            <div className="flex justify-center gap-6 space-x-4">
                                <Link to="https://www.facebook.com/ClubAmerica/?locale=es_LA" target="_blank" aria-label="Facebook" rel="noopener noreferrer">
                                    <img src={Facebook} className="social-icons" alt="Facebook" />
                                </Link>
                                <Link to="https://www.instagram.com/clubamerica/" target="_blank" aria-label="Instagram" rel="noopener noreferrer">
                                    <img src={Instagram} className="social-icons" alt="Instagram" />
                                </Link>
                                <Link to="https://x.com/clubamerica_en?lang=es" target="_blank" aria-label="X" rel="noopener noreferrer">
                                    <img src={X} className="social-icons" alt="X" />
                                </Link>
                                <Link to="https://www.youtube.com/@clubamerica" target="_blank" aria-label="YT" rel="noopener noreferrer">
                                    <img src={YT} className="social-icons" alt="YT" />
                                </Link>
                            </div>
      
                    </div>
                </div>
            </div>

        </footer>
    );
}
export default Footer;