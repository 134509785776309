import { motion } from "framer-motion";
import { fadeIn } from '../../variants';
import './Ballon.css';

const Ballon = ({ src, nameOfClass, children }) => {

    return (
        <>
            <motion.figure 
            variants={fadeIn("down", 0.4)}
            initial="hidden"
            whileInView={"show"}
            viewport={{ once: false, amount: 0.3 }}
            className={nameOfClass}>
                <motion.div
                alt="Bouncing ball"
                className='ballon'
                animate={{
                    y: [-30, 10, -30], 
                }}
                transition={{
                    duration: 1.8,
                    ease: "easeInOut",
                    repeat: Infinity,
                    repeatType: "loop",
                }}
                >
                    <img src={src} alt="Bouncing ball" className="absolute w-full h-full object-contain z-30"/>
                    <span className='relative z-40'>{children}</span>
                </motion.div>
            </motion.figure>
        </>
    )
}

export default Ballon;