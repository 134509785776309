import Apoyo1 from'../../assets/img/Apoyo/apoyamos-jovenes-1.png?react';
import Apoyo2 from'../../assets/img/Apoyo/apoyamos-jovenes-2.png?react';
import Apoyo3 from'../../assets/img/Apoyo/apoyamos-jovenes-3.png?react';
import { motion } from "framer-motion";
import { fadeInContainerItemsAll, fadeInItem } from '../../variants';

const Apoyo = () => {
    return (  
        <>
        <div className="bg-white py-10 px-4 overflow-hidden">
            <h2 className="title-apoyo"> 
                Apoyamos a jóvenes de:
            </h2>
            <motion.div 
            variants={fadeInContainerItemsAll}
            initial="hidden"
            whileInView="visible"
            viewport={{ once: false, amount: 0.3 }}
            className="flex flex-wrap justify-between align-content-center max-md:gap-3">
                <motion.div 
                variants={fadeInItem}
                className="flex flex-col items-center md:w-1/3 max-md:w-full">
                    <img src={Apoyo1} alt="Apoyo1"/>
                    <p className="-mt-5 bg-orange-500 opacity-80 px-4 py-2 title-titles flex-shrink-0">
                        Comunidad Mazahua <br /> Del Estado De México
                    </p>
                </motion.div>
                <motion.div 
                variants={fadeInItem}
                className="flex flex-col items-center md:w-1/3 max-md:w-full">
                    <img src={Apoyo2} alt="Apoyo2"/>
                    <p className="-mt-5 bg-orange-500 m-9 px-4 py-2 opacity-80 title-titles  flex-shrink-0">
                        Rarámuris, En <br />Chihuahua
                    </p>
                </motion.div>
                <motion.div 
                variants={fadeInItem}
                className="flex flex-col items-center md:w-1/3 max-md:w-full">
                    <img src={Apoyo3} alt="Apoyo3"/>
                    <p className="-mt-5 bg-orange-500 px-4 py-2 opacity-80 title-titles flex-shrink-0">
                    Estudiantes <br />De Oaxaca
                    </p>
                </motion.div>
            </motion.div>
            
        </div>
        </>
    );
}
export default Apoyo;