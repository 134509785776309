import tecnologicas from '../../assets/img/Tecnologicas/aguilastecnologicas.png?react';
import genero from '../../assets/img/Tecnologicas/violenciadegenero.png?react';
import vistaAguila from '../../assets/img/Vista-de-Aguila/vista-aguila.png?react';
import Ballon from '../../atoms/Ballon/Ballon';
import balon from '../../assets/img/balon.png';
import { motion } from "framer-motion";
import { fadeIn } from '../../variants';
import { Link } from 'react-router-dom';
import CountUp from 'react-countup';
import './Tecnologicas.css';

const Tecnologicas = () => {
    return (
        <>
            <div className='boxTecnologica overflow-hidden pt-16' id="aguilas_tecnologicas">
                <motion.article 
                variants={fadeIn("left", 0.4)}
                initial="hidden"
                whileInView={"show"}
                viewport={{ once: false, amount: 0.3 }}
                className="boxTecnologica1 pl-10 max-md:text-left">
                    <h2 className='text-titles'>águilas tecnológicas.</h2>
                    <p className='title-sub-seccion-tec mb-4'>
                        600 estudiantes beneficiadas 
                    </p>
                    <p className='texts'>
                        Impulsamos a mujeres jóvenes con la impartición de cursos, talleres y clínicas enfocados en tecnología. Nuestro objetivo final es que las niñas y jóvenes mexicanas estudien carreras STEM para cerrar la brecha de género. 
                    </p>
                </motion.article>
                <motion.figure 
                variants={fadeIn("right", 0.4)}
                initial="hidden"
                whileInView={"show"}
                viewport={{ once: false, amount: 0.3 }}
                className='boxTecnologica2 contraLaViolencia'>
                    <img src={genero} alt="15 toneladas" className='w-full imagenContraLaViolencia' />
                </motion.figure>
                <motion.figure 
                variants={fadeIn("left", 0.4)}
                initial="hidden"
                whileInView={"show"}
                viewport={{ once: false, amount: 0.3 }}
                className="boxTecnologica3 violenciaDeGenero">
                    <img src={tecnologicas} alt="15 toneladas" className='w-full imagenViolenciaDeGenero' />
                    <Ballon nameOfClass='ballonViolenciaDeGenero text-ballon' src={balon}></Ballon>
                </motion.figure>
                <motion.article 
                variants={fadeIn("right", 0.4)}
                initial="hidden"
                whileInView={"show"}
                viewport={{ once: false, amount: 0.3 }}
                className="boxTecnologica4 max-md:text-right max-md:pr-10">
                    <h2 className='text-titles'>violencia de género.</h2>
                    <p className='title-sub-seccion-tec mb-4'>
                        588 mujeres beneficiadas
                    </p>
                    <p className='texts'>
                        Con cursos de protección Integral en temas de violencia de género, empoderamiento, planificación familiar, educación sexual y desarrollo económico. 
                    </p>
                </motion.article>
                <motion.article
                variants={fadeIn("up", 0.5)}
                initial="hidden"
                whileInView={"show"}
                viewport={{ once: false, amount: 0.3 }}
                className='boxTecnologica5'>
                    <div className='bg-[#F0EB9A] p-5 pl-10 text-center'>
                        <h3 className='text-mujeres'>en yucatán, campeche y oaxaca</h3>
                    </div>
                </motion.article>
            </div>
            <div className="overflow-hidden boxVista max-mdf:bg-gradient-to-b max-mdf:from-[#fff] max-mdf:to-[#fdf6e3] my-10">
                <div className='boxVista4 bg-gradient-to-b mdf:from-[#fff] mdf:to-[#fdf6e3]'></div>
                <motion.article 
                variants={fadeIn("left", 0.4)}
                initial="hidden"
                whileInView={"show"}
                viewport={{ once: false, amount: 0.3 }}
                className="boxVista1 sm:pl-10 sm:pr-0 px-5">
                    <h2 className='text-titles'>vista de águila.</h2>
                    <p className='texts mb-4'>
                        Realizamos estudios de la vista a niños de escuelas públicas y entregamos lentes a aquellos estudiantes que lo requieren. con esto buscamos impulsar el desempeño académico y evitar la deserción escolar
                    </p>
                    <div className='sm:inline-block hidden'>
                    <Link to="https://www.youtube.com/watch?v=eioc-RoMJUw" target="_blank" className="boton-css" rel="noopener noreferrer">
                        VER VIDEO 
                    </Link>
                    </div>
                    <div className='flex items-center text-vista-aguila mt-4'>
                        <span>2,132 lentes<br/>donados</span>
                        <span className="sm:mx-8 mx-6">+</span>
                        <span>280 en partidos<br/>vs. san luis</span>
                        <span className="sm:mx-8 ms-6">=</span>
                    </div>
                    
                    <div className="sm:text-left text-center">
                        <CountUp 
                            className="texts-contador leading-none" 
                            enableScrollSpy={true}
                            start={0}
                            end={2412}
                            duration={3}
                        />
                            <h2 className="texts-bene leading-none">
                                BENEFICIADOS.
                            </h2>
                        <Link to="https://www.youtube.com/watch?v=eioc-RoMJUw" target="_blank" className="boton-css sm:hidden inline-block my-4 " rel="noopener noreferrer">
                            VER VIDEO 
                        </Link>    
                    </div>
                </motion.article>
                <motion.figure 
                    variants={fadeIn("right", 0.4)}
                    initial="hidden"
                    whileInView={"show"}
                    viewport={{ once: false, amount: 0.3 }}
                    className='boxVista2 z-20'>
                            <img src={vistaAguila} alt="15 toneladas"  className='w-full'/>
                </motion.figure>
                <motion.article 
                variants={fadeIn("left", 0.4)}
                initial="hidden"
                whileInView={"show"}
                viewport={{ once: false, amount: 0.3 }}
                className='boxVista3 bg-[#F0EB9A] p-5 pl-10 z-30'>
                    <span className='uppercase lg:text-xl xl:text-2xl 2xl:text-4xl font-rubik text-[#173D73]'>club san luis se suma a club américa para entregar <br />más lentes, gracias a los 7 goles anotados durante <br/> la serie de partidos. </span><br />
                </motion.article>
            </div>
        </>
    );
}

export default Tecnologicas;