import beneficiados from'../../assets/img/AlasGuerrero/img-beneficiados.png';
import alumnosydocentes from'../../assets/img/AlasGuerrero/img-alumnosydocentes.png';
import apoyoguerrero from'../../assets/img/AlasGuerrero/img-apoyoguerrero.png';
import Ballon from '../../atoms/Ballon/Ballon';
import guantes from '../../assets/img/guantes.png';
import { motion } from "framer-motion";
import { fadeIn, fadeInContainerItemsAll, fadeInItem } from '../../variants';
import CountUp from 'react-countup';

const AlasG = () => {
    return (  
        <>
            <div className=" bg-gradient-to-b from-[#ffff] to-[#f8f6d2] py-10 px-4 overflow-hidden">
                <motion.h2
                variants={fadeIn("down", 0.4)}
                initial="hidden"
                whileInView={"show"}
                viewport={{ once: false, amount: 0.3 }}
                className="text-titles mb-4 text-center">
                    NUESTRAS ALAS VUELAN A GUERRERO.
                </motion.h2>
                <motion.div 
                variants={fadeIn("down", 0.4)}
                initial="hidden"
                whileInView={"show"}
                viewport={{ once: false, amount: 0.3 }}
                className="flex flex-col items-center space-y-4 sm:mb-10">
                    <p className="text-gray-700 text-center texts">
                        En atención al llamado de emergencia por el huracán Otis que arrasó Acapulco, Club<br/> América se unió a los esfuerzos de ayuda a través de Nuestras Alas.
                    </p>
                    <p className="text-gray-700 text-center texts">
                        Club América y Estadio Azteca se aliaron para donar la taquilla de los partidos<br/> América vs. Xolos Varonil y América vs. Chivas Femenil. Este donativo se multiplicó<br/> gracias a Fundación Televisa y Fundación Banorte y se convirtió en:
                    </p>
                    <div className="text-center leading-none">
                        <div className="text-contador-35"> 
                            <span className="text-contador-35">$</span>
                            <span className="text-contador-35">
                            <CountUp
                            enableScrollSpy={true}
                            start={0}
                            end={35}
                            duration={4}
                            />
                        </span> MILLONES </div>
                        <div className="text-de-pesos">DE PESOS.</div>
                    </div>
                </motion.div>
                <motion.div 
                variants={fadeInContainerItemsAll}
                initial="hidden"
                whileInView="visible"
                viewport={{ once: false, amount: 0.1 }}
                className="flex flex-wrap justify-between align-content-center">
                    <motion.div
                    variants={fadeInItem}
                    className="text-xl text-center w-1/3 max-md:w-full p-8">
                        <img src={apoyoguerrero} alt="apoyoguerrero" className='mx-auto mt-10 mb-10 '/>
                        <span className="text-numeros">
                            <CountUp
                            enableScrollSpy={true}
                            start={0}
                            end={41}
                            duration={3}
                            />
                        </span>
                        <span className="text-numeros"> MIL </span>
                        <h1 className='text-de-titulos'>
                            BENEFICIADOS
                        </h1>
                        <p className='text-de-parrafo'>
                        Con despensas que se enviaron a través de Cruz Roja Guerrero, durante los días más críticos de la emergencia.  
                        </p>
                    
                    </motion.div>
                    <motion.div className="text-xl text-center w-1/3 max-md:w-full p-8">
                        <img src={alumnosydocentes} alt="alumnosydocentes" className='mx-auto mt-10 mb-10'/>
                        <span className="text-numeros"> 
                            <CountUp
                            enableScrollSpy={true}
                            start={0}
                            end={704}
                            duration={3}
                            />
                        </span>
                        <h1 className='text-de-titulos'>
                            ALUMNOS Y DOCENTES.
                        </h1>
                        <p className='text-de-parrafo'>
                        Beneficiados con la entrega de 3 aulas de medios con conexión a internet y capacitación en Acapulco y Coyuca
                        de Benitez.   
                        </p>
                    </motion.div>
                    <motion.div className="text-xl text-center w-1/3 max-md:w-full p-8 relative">
                        <Ballon nameOfClass='text-ballon absolute z-10 -top-6 right-5 lg:right-6 2xl:right-35 mdf:right-2 max-mdf:right-0 max-mdf:top-0' src={guantes}></Ballon>
                        <img src={beneficiados} alt="beneficiados" className=' mx-auto mt-10 mb-10'/>
                        <div className="text-center">
                        <span className="text-numeros">$</span>
                        <span className="text-numeros">
                            <CountUp
                            enableScrollSpy={true}
                            start={0}
                            end={1.8}
                            duration={3}
                            decimals={1} 
                            />
                        </span>
                        <span className="text-numeros"> MILLONES </span><br/>
                    </div>
                    <h1 className='text-de-titulos'>
                            De pesos
                    </h1>
                        <p className='text-de-parrafo'>
                        Para continuar con el apoyo a Guerrero, a principios de 2024 se planea entregar una ambulancia equipada.
                        </p>
                    </motion.div>
                </motion.div>
            </div>
        </>
 
);
}
export default AlasG;