import Header from "../components/Header/Header";
import Pilares from "../components/Pilares/Pilares";
import Onu from "../components/Onu/Onu";
import Footer from "../components/Footer/Footer";
import Tenis from "../components/Tenis/Tenis";
import Lanzamiento from "../components/Lanzamiento/Lanzamiento";
import Tecnologicas from "../components/Tecnologicas/Tecnologicas";
import EquiposEstraordinarios from "../components/EquiposEstraordinarios/EquiposExtraordinarios";
import Apoyo from "../components/Apoyo/Apoyo";
import Futbol from "../components/Futbol/Futbol";
import AlasG from "../components/AlasGuerrero/AlasG";
import Juega from "../components/Juega/Juega";
import JornadaR from "../components/JornadaRosa/JornadaR";
import Fundaciones from "../components/Fundaciones/Fundaciones";
import Semestre from "../components/Semestre/Semestre";
import RespaldoCA from "../components/RespaldoClubAmerica/RespaldoCA";
import juegaconnike from'../assets/img/JuegaconNike/juegaconnike.png';
import comunicacion from'../assets/img/Comunicacion/img-comunicacion.png';
import Eventos from '../components/Eventos/Eventos';
import ScrollButton from '../atoms/Scroll/ScrollButton';

const Home = () => {
    return (
        <>
            <Header />
            <Pilares />
            <Lanzamiento />
            <Futbol />
            <Tenis />
            <Apoyo/>
            <Tecnologicas />
            <AlasG/>
            <EquiposEstraordinarios />
            <Juega img={juegaconnike} texto="JUEGA MÁS CON NIKE" id="juega_con_nike"/>
            <Semestre>En el primer semestre del año se inició el programa Juega Más, que<br className="sm:block hidden"/> 
            tiene el objetivo de fomentar la presencia de educación física en las <br className="sm:block hidden"/>
            escuelas. Se han beneficiado a más de 100 niños y niñas.</Semestre>
            <JornadaR/>
            <Eventos/>
            <Fundaciones/>
            <Juega img={comunicacion} texto="COMUNICACIÓN."/> 
            <Semestre>El respaldo del Club América y Fundación Televisa amplia el alcance de <br className="sm:block hidden"/>
            Nuestras Alas, especialmente en las redes sociales, donde llegamos a<br className="sm:block hidden"/>
             miles de seguidores.</Semestre>
            <RespaldoCA />
            <Onu/>
            <Footer /> 
            <ScrollButton />
        </>
    );
};

export default Home;