import nina  from '../../assets/img/header/nina-header.png?react';
import nina_tablet  from '../../assets/img/header/nina-tablet.png?react';
import balon  from '../../assets/img/header/balon-header.svg?react';
import logo  from '../../assets/img/logo.svg?react';
import { motion } from "framer-motion";
import { fadeIn } from '../../variants';
import { useTranslation } from "react-i18next";
import Menu from '../../atoms/Menu/Menu';
import './Header.css';

const Header = () => {
    const { t } = useTranslation();

  return (
    <header id="nuestras_alas" className="boxHeader overflow-hidden bg-[#093067] bg-no-repeat bg-cover bg-center mdf:h-screen" style={{ backgroundImage: `url(${balon})` }}>
        <figure className='boxHeader1 z-30 pl-10 pt-10 max-md:pl-5 max-md:pt-5'>
            <img src={logo} className="logo" alt="Logo" />   
        </figure>

        <motion.div 
        variants={fadeIn("left", 0.4)}
        initial="hidden"
        whileInView={"show"}
        viewport={{ once: false, amount: 0.3 }}
        className="boxHeader2 text-[#093067] bg-gradient-to-t from-white to-95% z-30 image2 md:mx-[3.12rem] md:p-5 py-5">
            <h2 className="text-title-header-informe leading-none">{t('informe')}</h2>
            <h1 className="text-title-header leading-none">NUESTRAS ALAS</h1>
        </motion.div>

        <motion.figure 
        variants={fadeIn("down", 0.4)}
        initial="hidden"
        whileInView={"show"}
        viewport={{ once: false, amount: 0.3 }}
        className="boxHeader3 z-20">
            <picture className='headimage'>
                <source srcSet={nina} media="(min-width: 1024px)" />
                <source srcSet={nina_tablet} media="(max-width: 768px)" />
                <img
                    src={nina}
                    alt="Tenis recolectados"
                    className="w-full"
                />
            </picture>
        </motion.figure>

        <Menu />
    </header>
  );
}

export default Header;